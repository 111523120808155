html {
    height: 100%;
    font-family: 'sans-serif';
}

@font-face {
  font-family: 'avenir-medium';
  src:  url('./fonts/avenir-medium.ttf?8l9dlm');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'avenir-roman';
  src:  url('./fonts/avenir-roman.ttf?8l9dlm');
  font-weight: normal;
  font-style: normal;
}

.ui.menu {
  font-family: 'Source Sans Pro', sans-serif;
}
.ui.button {
  font-family: 'Source Sans Pro', sans-serif;
}

body {
    margin: 0px;
    height: 100%;
    font-family: 'Source Sans Pro', sans-serif;
}

.bold
{
	font-weight:bold;
}

.font-smaller
{
    font-size:1em;
}

.login-other * {
    display:block;
}

.nav-right-container {
    float: right;
}

.buttonLink {
    color: black;
    text-decoration: none;
    background-color: #f2f2f2;
    height: 30px;
    border-radius: 5px;
    margin: 2px;
    padding: 2px;
    margin: 2px;
}

.profile-picture
{
    width: 8em; 
    height:8em; 
    border-radius: 50%;
}

#root {
    height: 100%;
}

#root > div {
    height: 100%;
}

.themeGrey {
    background-color: rgb(249,249,249) !important;
}
.themeDarkGrey {
    background-color: rgb(221,221,221) !important;
}

.ui.blue.button {
    background-color: #1E0360;
}
.ui.blue.button:focus {
    background-color: #1E0360;
}
.ui.blue.button:active {
    background-color: #1E0360;
}
.ui.blue.button:hover {
    background-color: #030390;
}

.clickable {
    cursor: pointer;
}

.notClickable {
    cursor: default !important;
}


.full-height {
  height: 100%;
}

.full-width {
    width: 100%;
}

/*
.scrollable {
    overflow-y: auto;
    height: 100%;
}*/

.scrollable {
 /* webkit bug forces using scroll instead of auto to enable scroll momentum */
    overflow-y: scroll;
    height: 100%;
    -webkit-overflow-scrolling: touch;
}

.inline {
    display: inline;
}

.inlineBlock {
    display: inline-block;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.DayPickerInput-OverlayWrapper {
    z-index: 5;
}

.borderless.ui.cards > .ui.card {
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.borderless.ui.cards > .ui.card > :only-child {
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
}
.borderless.ui.cards > .ui.card > :first-child {
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
}
.borderless.ui.cards > .ui.card > :last-child {
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
}


.NavBar.ui.menu {
    border: none;
}
.NavBar.ui.menu .item:before {
    background: none;
}

.terms-and-conditions p {
    margin: 16px;
}

.ui.green.toggle.checkbox input:checked~.box:before, .ui.toggle.green.checkbox input:checked~label:before {
    background-color: #8ce196 !important;
}

.ui.green.toggle.checkbox .box:before, .ui.toggle.green.checkbox label:before {
    background-color: #bbbdbf !important;
}

.ui.cards a.card.nohover:hover, .ui.link.card.nohover:hover, .ui.link.cards .card.nohover:hover, a.ui.card.nohover:hover {
    transform: none;
}

.ui.dimmer .ui.loader.notInDimmer:after {
    border-bottom-color: rgb(118, 118, 118);
    border-left-color: rgb(118, 118, 118);
    border-right-color: rgb(118, 118, 118);
    border-top-color: #fff transparent transparent;
}

.ui.modal > .close {
    top: 1.0535rem;
    right: 1rem;
    color: rgba(0,0,0,.87);
}

.ui.search > .results {
    word-break: break-all;
    overflow-x: auto;
    overflow-y: auto;
    max-height: 300px;
}