.icon-font {
	font-family:'Dooo-Icons';
}

@icon-leaderboard: "\e602";
@icon-menu: "\e605";
@icon-profile: "\e603";
@icon-settings: "\e600";
@icon-Today: "Today";
@icon-password: "\e608";
@icon-myhealthsphere-logo-type: "\e66d";
@icon-myHealthSphere-logo: "\e612";
@icon-logotype: "\e683";
@icon-add-invert: "\e71f";
@icon-play2: "\e64b";
@icon-arrow-up: "\e61c";
@icon-arrow-down: "\e61b";

/*** Paste Icon CSS Below ***/
/*** Comment out line-height:1 */

@font-face {
  font-family: 'Dooo-Icons';
  src:  url('./fonts/Dooo-Icons.eot?8l9dlm');
  src:  url('./fonts/Dooo-Icons.eot?8l9dlm#iefix') format('embedded-opentype'),
        url('./fonts/Dooo-Icons.ttf?8l9dlm') format('truetype'),
        url('./fonts/Dooo-Icons.woff?8l9dlm') format('woff'),
        url('./fonts/Dooo-Icons.svg?8l9dlm#Dooo-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Dooo-Icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /*line-height: 1;*/

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Optimollar:before {
  content: "\e900";
}
.icon-heart3:before {
  content: "\2665";
}
.icon-zap:before {
  content: "\26a1";
}
.icon-eye3:before {
  content: "\e000";
}
.icon-paper-clip:before {
  content: "\e001";
}
.icon-mail3:before {
  content: "\e002";
}
.icon-toggle:before {
  content: "\e003";
}
.icon-layout:before {
  content: "\e004";
}
.icon-link2:before {
  content: "\e005";
}
.icon-bell:before {
  content: "\e006";
}
.icon-lock2:before {
  content: "\e007";
}
.icon-unlock:before {
  content: "\e008";
}
.icon-ribbon:before {
  content: "\e009";
}
.icon-image2:before {
  content: "\e010";
}
.icon-signal:before {
  content: "\e011";
}
.icon-target2:before {
  content: "\e012";
}
.icon-clipboard:before {
  content: "\e013";
}
.icon-clock3:before {
  content: "\e014";
}
.icon-watch:before {
  content: "\e015";
}
.icon-air-play:before {
  content: "\e016";
}
.icon-camera3:before {
  content: "\e017";
}
.icon-video2:before {
  content: "\e018";
}
.icon-disc:before {
  content: "\e019";
}
.icon-printer:before {
  content: "\e020";
}
.icon-monitor:before {
  content: "\e021";
}
.icon-server:before {
  content: "\e022";
}
.icon-cog2:before {
  content: "\e023";
}
.icon-heart2:before {
  content: "\e024";
}
.icon-paragraph:before {
  content: "\e025";
}
.icon-align-justify:before {
  content: "\e026";
}
.icon-align-left:before {
  content: "\e027";
}
.icon-align-center:before {
  content: "\e028";
}
.icon-align-right:before {
  content: "\e029";
}
.icon-book3:before {
  content: "\e030";
}
.icon-layers2:before {
  content: "\e031";
}
.icon-stack2:before {
  content: "\e032";
}
.icon-stack-2:before {
  content: "\e033";
}
.icon-paper:before {
  content: "\e034";
}
.icon-paper-stack:before {
  content: "\e035";
}
.icon-search2:before {
  content: "\e036";
}
.icon-zoom-in:before {
  content: "\e037";
}
.icon-zoom-out:before {
  content: "\e038";
}
.icon-reply:before {
  content: "\e039";
}
.icon-circle-plus:before {
  content: "\e040";
}
.icon-circle-minus:before {
  content: "\e041";
}
.icon-circle-check:before {
  content: "\e042";
}
.icon-circle-cross:before {
  content: "\e043";
}
.icon-square-plus:before {
  content: "\e044";
}
.icon-square-minus:before {
  content: "\e045";
}
.icon-square-check:before {
  content: "\e046";
}
.icon-square-cross:before {
  content: "\e047";
}
.icon-microphone2:before {
  content: "\e048";
}
.icon-record:before {
  content: "\e049";
}
.icon-skip-back:before {
  content: "\e050";
}
.icon-rewind:before {
  content: "\e051";
}
.icon-play4:before {
  content: "\e052";
}
.icon-pause3:before {
  content: "\e053";
}
.icon-stop3:before {
  content: "\e054";
}
.icon-fast-forward:before {
  content: "\e055";
}
.icon-skip-forward:before {
  content: "\e056";
}
.icon-shuffle:before {
  content: "\e057";
}
.icon-repeat:before {
  content: "\e058";
}
.icon-folder:before {
  content: "\e059";
}
.icon-umbrella2:before {
  content: "\e060";
}
.icon-moon:before {
  content: "\e061";
}
.icon-thermometer:before {
  content: "\e062";
}
.icon-drop:before {
  content: "\e063";
}
.icon-sun:before {
  content: "\e064";
}
.icon-cloud3:before {
  content: "\e065";
}
.icon-cloud-upload2:before {
  content: "\e066";
}
.icon-cloud-download2:before {
  content: "\e067";
}
.icon-upload2:before {
  content: "\e068";
}
.icon-download2:before {
  content: "\e069";
}
.icon-location2:before {
  content: "\e070";
}
.icon-location-2:before {
  content: "\e071";
}
.icon-map:before {
  content: "\e072";
}
.icon-battery:before {
  content: "\e073";
}
.icon-head:before {
  content: "\e074";
}
.icon-briefcase:before {
  content: "\e075";
}
.icon-speech-bubble:before {
  content: "\e076";
}
.icon-anchor:before {
  content: "\e077";
}
.icon-globe:before {
  content: "\e078";
}
.icon-box2:before {
  content: "\e079";
}
.icon-reload2:before {
  content: "\e080";
}
.icon-share2:before {
  content: "\e081";
}
.icon-marquee:before {
  content: "\e082";
}
.icon-marquee-plus:before {
  content: "\e083";
}
.icon-marquee-minus:before {
  content: "\e084";
}
.icon-tag2:before {
  content: "\e085";
}
.icon-power:before {
  content: "\e086";
}
.icon-command:before {
  content: "\e087";
}
.icon-alt:before {
  content: "\e088";
}
.icon-esc:before {
  content: "\e089";
}
.icon-bar-graph:before {
  content: "\e090";
}
.icon-bar-graph-2:before {
  content: "\e091";
}
.icon-pie-graph:before {
  content: "\e092";
}
.icon-arrow-left3:before {
  content: "\e094";
}
.icon-arrow-right3:before {
  content: "\e095";
}
.icon-arrow-up3:before {
  content: "\e096";
}
.icon-arrow-down3:before {
  content: "\e097";
}
.icon-volume2:before {
  content: "\e098";
}
.icon-mute:before {
  content: "\e099";
}
.icon-content-right:before {
  content: "\e100";
}
.icon-content-left:before {
  content: "\e101";
}
.icon-grid:before {
  content: "\e102";
}
.icon-grid-2:before {
  content: "\e103";
}
.icon-columns:before {
  content: "\e104";
}
.icon-loader:before {
  content: "\e105";
}
.icon-bag:before {
  content: "\e106";
}
.icon-ban:before {
  content: "\e107";
}
.icon-flag:before {
  content: "\e108";
}
.icon-trash2:before {
  content: "\e109";
}
.icon-expand:before {
  content: "\e110";
}
.icon-contract:before {
  content: "\e111";
}
.icon-maximize:before {
  content: "\e112";
}
.icon-minimize:before {
  content: "\e113";
}
.icon-plus3:before {
  content: "\e114";
}
.icon-minus3:before {
  content: "\e115";
}
.icon-check2:before {
  content: "\e116";
}
.icon-cross:before {
  content: "\e117";
}
.icon-move2:before {
  content: "\e118";
}
.icon-delete:before {
  content: "\e119";
}
.icon-menu2:before {
  content: "\e120";
}
.icon-archive:before {
  content: "\e121";
}
.icon-inbox2:before {
  content: "\e122";
}
.icon-outbox:before {
  content: "\e123";
}
.icon-file:before {
  content: "\e124";
}
.icon-file-add:before {
  content: "\e125";
}
.icon-file-subtract:before {
  content: "\e126";
}
.icon-help:before {
  content: "\e127";
}
.icon-open:before {
  content: "\e128";
}
.icon-ellipsis:before {
  content: "\e129";
}
.icon-settings:before {
  content: "\e600";
}
.icon-rewards:before {
  content: "\e601";
}
.icon-leaderboard:before {
  content: "\e602";
}
.icon-profile:before {
  content: "\e603";
}
.icon-menu:before {
  content: "\e605";
}
.icon-chat:before {
  content: "\e606";
}
.icon-logo:before {
  content: "\e607";
}
.icon-password:before {
  content: "\e608";
}
.icon-google:before {
  content: "\e609";
}
.icon-facebook:before {
  content: "\e60a";
}
.icon-error:before {
  content: "\e60b";
}
.icon-correct:before {
  content: "\e60c";
}
.icon-challenges:before {
  content: "\e60d";
}
.icon-back:before {
  content: "\e60e";
}
.icon-chat-alt-stroke:before {
  content: "\e60f";
}
.icon-sleep:before {
  content: "\e610";
}
.icon-team:before {
  content: "\e611";
}
.icon-myHealthSphere-logo:before {
  content: "\e612";
}
.icon-goal:before {
  content: "\e613";
}
.icon-forward:before {
  content: "\e614";
}
.icon-shopping-cart:before {
  content: "\e615";
}
.icon-signup:before {
  content: "\e616";
}
.icon-pencil:before {
  content: "\e617";
}
.icon-banknote:before {
  content: "\e618";
}
.icon-mobile:before {
  content: "\e619";
}
.icon-arrow-left:before {
  content: "\e61a";
}
.icon-arrow-down:before {
  content: "\e61b";
}
.icon-arrow-up:before {
  content: "\e61c";
}
.icon-arrow-right:before {
  content: "\e61d";
}
.icon-chat-alt-fill:before {
  content: "\e61e";
}
.icon-happy:before {
  content: "\e61f";
}
.icon-sad:before {
  content: "\e620";
}
.icon-cool:before {
  content: "\e621";
}
.icon-angry:before {
  content: "\e622";
}
.icon-confused:before {
  content: "\e623";
}
.icon-neutral:before {
  content: "\e624";
}
.icon-wondering:before {
  content: "\e625";
}
.icon-play:before {
  content: "\e626";
}
.icon-previous:before {
  content: "\e627";
}
.icon-next:before {
  content: "\e628";
}
.icon-chrome:before {
  content: "\e629";
}
.icon-task:before {
  content: "\e62a";
}
.icon-inbox:before {
  content: "\e62b";
}
.icon-cup:before {
  content: "\e62c";
}
.icon-like:before {
  content: "\e62d";
}
.icon-music:before {
  content: "\e62e";
}
.icon-food:before {
  content: "\e62f";
}
.icon-comment-alt1-stroke:before {
  content: "\e630";
}
.icon-comment:before {
  content: "\e631";
}
.icon-comment-stroke:before {
  content: "\e632";
}
.icon-comment-fill:before {
  content: "\e633";
}
.icon-uniE682:before {
  content: "\e634";
}
.icon-uniE681:before {
  content: "\e635";
}
.icon-screen:before {
  content: "\e636";
}
.icon-display:before {
  content: "\e637";
}
.icon-comment-alt2-stroke:before {
  content: "\e638";
}
.icon-comment-alt2-fill:before {
  content: "\e639";
}
.icon-checkmark:before {
  content: "\e63a";
}
.icon-check-alt:before {
  content: "\e63b";
}
.icon-x2:before {
  content: "\e63c";
}
.icon-x-altx-alt:before {
  content: "\e63d";
}
.icon-denied:before {
  content: "\e63e";
}
.icon-cursor:before {
  content: "\e63f";
}
.icon-rss:before {
  content: "\e640";
}
.icon-rss-alt:before {
  content: "\e641";
}
.icon-wrench:before {
  content: "\e642";
}
.icon-dial:before {
  content: "\e643";
}
.icon-cog:before {
  content: "\e644";
}
.icon-phone:before {
  content: "\e645";
}
.icon-calendar:before {
  content: "\e646";
}
.icon-calendar-alt-stroke:before {
  content: "\e647";
}
.icon-calendar-alt-fill:before {
  content: "\e648";
}
.icon-share:before {
  content: "\e649";
}
.icon-mail:before {
  content: "\e64a";
}
.icon-play2:before {
  content: "\e64b";
}
.icon-pause:before {
  content: "\e64c";
}
.icon-stop:before {
  content: "\e64d";
}
.icon-heart-stroke:before {
  content: "\e64e";
}
.icon-heart-fill:before {
  content: "\e64f";
}
.icon-movie:before {
  content: "\e650";
}
.icon-document-alt-stroke:before {
  content: "\e651";
}
.icon-article:before {
  content: "\e652";
}
.icon-training:before {
  content: "\e653";
}
.icon-timer:before {
  content: "\e654";
}
.icon-stretching:before {
  content: "\e655";
}
.icon-relaxation:before {
  content: "\e656";
}
.icon-organization:before {
  content: "\e657";
}
.icon-document-alt-fill:before {
  content: "\e658";
}
.icon-document-stroke:before {
  content: "\e659";
}
.icon-document-fill:before {
  content: "\e65a";
}
.icon-plus2:before {
  content: "\e65b";
}
.icon-plus-alt:before {
  content: "\e65c";
}
.icon-minus2:before {
  content: "\e65d";
}
.icon-minus-alt:before {
  content: "\e65e";
}
.icon-pin:before {
  content: "\e65f";
}
.icon-link:before {
  content: "\e660";
}
.icon-bolt:before {
  content: "\e661";
}
.icon-move:before {
  content: "\e662";
}
.icon-x:before {
  content: "\e663";
}
.icon-up:before {
  content: "\e664";
}
.icon-twitter:before {
  content: "\e665";
}
.icon-tired:before {
  content: "\e666";
}
.icon-spin:before {
  content: "\e667";
}
.icon-sad2:before {
  content: "\e668";
}
.icon-phone2:before {
  content: "\e669";
}
.icon-hint-logs:before {
  content: "\e66a";
}
.icon-hint-tutorial:before {
  content: "\e66b";
}
.icon-social:before {
  content: "\e66c";
}
.icon-uniE680:before {
  content: "\e66d";
}
.icon-move-alt1:before {
  content: "\e66e";
}
.icon-linkedin:before {
  content: "\e66f";
}
.icon-linkedin-invert:before {
  content: "\e670";
}
.icon-female:before {
  content: "\e671";
}
.icon-facebooK-invert:before {
  content: "\e672";
}
.icon-move-alt2:before {
  content: "\e673";
}
.icon-equalizer:before {
  content: "\e674";
}
.icon-award-fill:before {
  content: "\e675";
}
.icon-award-stroke:before {
  content: "\e676";
}
.icon-content:before {
  content: "\e677";
}
.icon-magnifying-glass:before {
  content: "\e678";
}
.icon-trash-stroke:before {
  content: "\e679";
}
.icon-check:before {
  content: "\e67a";
}
.icon-gift:before {
  content: "\e67b";
}
.icon-checklist:before {
  content: "\e67c";
}
.icon-pulse:before {
  content: "\e67d";
}
.icon-education:before {
  content: "\e67e";
}
.icon-wash-hand:before {
  content: "\e67f";
}
.icon-hard-hat:before {
  content: "\e680";
}
.icon-three-star:before {
  content: "\e681";
}
.icon-banana:before {
  content: "\e682";
}
.icon-uniE67F:before {
  content: "\e683";
}
.icon-uniE67E:before {
  content: "\e684";
}
.icon-uniE67D:before {
  content: "\e685";
}
.icon-book:before {
  content: "\e686";
}
.icon-brain:before {
  content: "\e687";
}
.icon-trash-fill:before {
  content: "\e688";
}
.icon-beaker-alt:before {
  content: "\e689";
}
.icon-beaker:before {
  content: "\e68a";
}
.icon-key-stroke:before {
  content: "\e68b";
}
.icon-key-fill:before {
  content: "\e68c";
}
.icon-new-window:before {
  content: "\e68d";
}
.icon-lightbulb:before {
  content: "\e68e";
}
.icon-spin-alt:before {
  content: "\e68f";
}
.icon-spin2:before {
  content: "\e690";
}
.icon-curved-arrow:before {
  content: "\e691";
}
.icon-undo:before {
  content: "\e692";
}
.icon-reload:before {
  content: "\e693";
}
.icon-reload-alt:before {
  content: "\e694";
}
.icon-loop:before {
  content: "\e695";
}
.icon-loop-alt1:before {
  content: "\e696";
}
.icon-loop-alt2:before {
  content: "\e697";
}
.icon-loop-alt3:before {
  content: "\e698";
}
.icon-loop-alt4:before {
  content: "\e699";
}
.icon-transfer:before {
  content: "\e69a";
}
.icon-move-vertical:before {
  content: "\e69b";
}
.icon-move-vertical-alt1:before {
  content: "\e69c";
}
.icon-move-vertical-alt2:before {
  content: "\e69d";
}
.icon-move-horizontal:before {
  content: "\e69e";
}
.icon-move-horizontal-alt1:before {
  content: "\e69f";
}
.icon-move-horizontal-alt2:before {
  content: "\e6a0";
}
.icon-arrow-left-thick:before {
  content: "\e6a1";
}
.icon-arrow-left-alt1:before {
  content: "\e6a2";
}
.icon-arrow-left-alt2:before {
  content: "\e6a3";
}
.icon-arrow-right-thick:before {
  content: "\e6a4";
}
.icon-arrow-right-alt1:before {
  content: "\e6a5";
}
.icon-arrow-right-alt2:before {
  content: "\e6a6";
}
.icon-arrow-up-thick:before {
  content: "\e6a7";
}
.icon-arrow-up-alt1:before {
  content: "\e6a8";
}
.icon-arrow-up-alt2:before {
  content: "\e6a9";
}
.icon-arrow-down-thick:before {
  content: "\e6aa";
}
.icon-arrow-down-alt1:before {
  content: "\e6ab";
}
.icon-arrow-down-alt2:before {
  content: "\e6ac";
}
.icon-cd:before {
  content: "\e6ad";
}
.icon-steering-wheel:before {
  content: "\e6ae";
}
.icon-microphone:before {
  content: "\e6af";
}
.icon-headphones:before {
  content: "\e6b0";
}
.icon-volume:before {
  content: "\e6b1";
}
.icon-volume-mute:before {
  content: "\e6b2";
}
.icon-play3:before {
  content: "\e6b3";
}
.icon-pause2:before {
  content: "\e6b4";
}
.icon-stop2:before {
  content: "\e6b5";
}
.icon-eject:before {
  content: "\e6b6";
}
.icon-first:before {
  content: "\e6b7";
}
.icon-last:before {
  content: "\e6b8";
}
.icon-play-alt:before {
  content: "\e6b9";
}
.icon-fullscreen-exit:before {
  content: "\e6ba";
}
.icon-fullscreen-exit-alt:before {
  content: "\e6bb";
}
.icon-fullscreen:before {
  content: "\e6bc";
}
.icon-fullscreen-alt:before {
  content: "\e6bd";
}
.icon-iphone:before {
  content: "\e6be";
}
.icon-battery-empty:before {
  content: "\e6bf";
}
.icon-battery-half:before {
  content: "\e6c0";
}
.icon-battery-full:before {
  content: "\e6c1";
}
.icon-battery-charging:before {
  content: "\e6c2";
}
.icon-compass:before {
  content: "\e6c3";
}
.icon-box:before {
  content: "\e6c4";
}
.icon-folder-stroke:before {
  content: "\e6c5";
}
.icon-folder-fill:before {
  content: "\e6c6";
}
.icon-at:before {
  content: "\e6c7";
}
.icon-ampersand:before {
  content: "\e6c8";
}
.icon-info:before {
  content: "\e6c9";
}
.icon-question-mark:before {
  content: "\e6ca";
}
.icon-pilcrow:before {
  content: "\e6cb";
}
.icon-hash:before {
  content: "\e6cc";
}
.icon-left-quote:before {
  content: "\e6cd";
}
.icon-right-quote:before {
  content: "\e6ce";
}
.icon-left-quote-alt:before {
  content: "\e6cf";
}
.icon-right-quote-alt:before {
  content: "\e6d0";
}
.icon-article2:before {
  content: "\e6d1";
}
.icon-read-more:before {
  content: "\e6d2";
}
.icon-list:before {
  content: "\e6d3";
}
.icon-list-nested:before {
  content: "\e6d4";
}
.icon-book2:before {
  content: "\e6d5";
}
.icon-book-alt:before {
  content: "\e6d6";
}
.icon-book-alt2:before {
  content: "\e6d7";
}
.icon-pen:before {
  content: "\e6d8";
}
.icon-pen-alt-stroke:before {
  content: "\e6d9";
}
.icon-pen-alt-fill:before {
  content: "\e6da";
}
.icon-pen-alt2:before {
  content: "\e6db";
}
.icon-brush:before {
  content: "\e6dc";
}
.icon-brush-alt:before {
  content: "\e6dd";
}
.icon-eyedropper:before {
  content: "\e6de";
}
.icon-layers-alt:before {
  content: "\e6df";
}
.icon-layers:before {
  content: "\e6e0";
}
.icon-image:before {
  content: "\e6e1";
}
.icon-camera:before {
  content: "\e6e2";
}
.icon-aperture:before {
  content: "\e6e3";
}
.icon-aperture-alt:before {
  content: "\e6e4";
}
.icon-chart:before {
  content: "\e6e5";
}
.icon-chart-alt:before {
  content: "\e6e6";
}
.icon-bars:before {
  content: "\e6e7";
}
.icon-bars-alt:before {
  content: "\e6e8";
}
.icon-eye:before {
  content: "\e6e9";
}
.icon-user:before {
  content: "\e6ea";
}
.icon-home:before {
  content: "\e6eb";
}
.icon-clock:before {
  content: "\e6ec";
}
.icon-lock-stroke:before {
  content: "\e6ed";
}
.icon-lock-fill:before {
  content: "\e6ee";
}
.icon-unlock-stroke:before {
  content: "\e6ef";
}
.icon-unlock-fill:before {
  content: "\e6f0";
}
.icon-tag-stroke:before {
  content: "\e6f1";
}
.icon-tag-fill:before {
  content: "\e6f2";
}
.icon-sun-stroke:before {
  content: "\e6f3";
}
.icon-sun-fill:before {
  content: "\e6f4";
}
.icon-moon-stroke:before {
  content: "\e6f5";
}
.icon-moon-fill:before {
  content: "\e6f6";
}
.icon-cloud:before {
  content: "\e6f7";
}
.icon-rain:before {
  content: "\e6f8";
}
.icon-umbrella:before {
  content: "\e6f9";
}
.icon-bell2:before {
  content: "\e6fa";
}
.icon-map-pin-stroke:before {
  content: "\e6fb";
}
.icon-map-pin-fill:before {
  content: "\e6fc";
}
.icon-map-pin-alt:before {
  content: "\e6fd";
}
.icon-target:before {
  content: "\e6fe";
}
.icon-download:before {
  content: "\e6ff";
}
.icon-upload:before {
  content: "\e700";
}
.icon-cloud-download:before {
  content: "\e701";
}
.icon-cloud-upload:before {
  content: "\e702";
}
.icon-fork:before {
  content: "\e703";
}
.icon-paperclip:before {
  content: "\e704";
}
.icon-heart:before {
  content: "\e705";
}
.icon-cloud2:before {
  content: "\e706";
}
.icon-alarm:before {
  content: "\e707";
}
.icon-tv:before {
  content: "\e708";
}
.icon-nutrition:before {
  content: "\e709";
}
.icon-nutrition-full:before {
  content: "\e70a";
}
.icon-nutrition-empty:before {
  content: "\e70b";
}
.icon-nutrition-add:before {
  content: "\e70c";
}
.icon-sound:before {
  content: "\e70d";
}
.icon-video:before {
  content: "\e70e";
}
.icon-trash:before {
  content: "\e70f";
}
.icon-user2:before {
  content: "\e710";
}
.icon-key:before {
  content: "\e711";
}
.icon-hydration:before {
  content: "\e712";
}
.icon-hydration-full:before {
  content: "\e713";
}
.icon-hydration-empty:before {
  content: "\e714";
}
.icon-hydration-add:before {
  content: "\e715";
}
.icon-search:before {
  content: "\e716";
}
.icon-settings2:before {
  content: "\e717";
}
.icon-camera2:before {
  content: "\e718";
}
.icon-add:before {
  content: "\e719";
}
.icon-tag:before {
  content: "\e71a";
}
.icon-lock:before {
  content: "\e71b";
}
.icon-bulb:before {
  content: "\e71c";
}
.icon-aerobics:before {
  content: "\e71d";
}
.icon-pen2:before {
  content: "\e71e";
}
.icon-add-invert:before {
  content: "\e71f";
}
.icon-diamond:before {
  content: "\e720";
}
.icon-display2:before {
  content: "\e721";
}
.icon-location:before {
  content: "\e722";
}
.icon-eye2:before {
  content: "\e723";
}
.icon-bubble:before {
  content: "\e724";
}
.icon-stack:before {
  content: "\e725";
}
.icon-cup2:before {
  content: "\e726";
}
.icon-phone3:before {
  content: "\e727";
}
.icon-news:before {
  content: "\e728";
}
.icon-mail2:before {
  content: "\e729";
}
.icon-like2:before {
  content: "\e72a";
}
.icon-photo:before {
  content: "\e72b";
}
.icon-note:before {
  content: "\e72c";
}
.icon-clock2:before {
  content: "\e72d";
}
.icon-paperplane:before {
  content: "\e72e";
}
.icon-params:before {
  content: "\e72f";
}
.icon-banknote2:before {
  content: "\e730";
}
.icon-data:before {
  content: "\e731";
}
.icon-music2:before {
  content: "\e732";
}
.icon-megaphone:before {
  content: "\e733";
}
.icon-study:before {
  content: "\e734";
}
.icon-lab:before {
  content: "\e735";
}
.icon-food2:before {
  content: "\e736";
}
.icon-t-shirt:before {
  content: "\e737";
}
.icon-fire:before {
  content: "\e738";
}
.icon-clip:before {
  content: "\e739";
}
.icon-shop:before {
  content: "\e73a";
}
.icon-calendar2:before {
  content: "\e73b";
}
.icon-wallet:before {
  content: "\e73c";
}
.icon-vynil:before {
  content: "\e73d";
}
.icon-truck:before {
  content: "\e73e";
}
.icon-world:before {
  content: "\e73f";
}
.icon-history:before {
  content: "\e740";
}
.icon-clock4:before {
  content: "\e741";
}
.icon-star-empty:before {
  content: "\e742";
}
.icon-compass2:before {
  content: "\e743";
}
.icon-star-full:before {
  content: "\e744";
}
.icon-plus:before {
  content: "\e775";
}
.icon-minus:before {
  content: "\e776";
}
.icon-male:before {
  content: "\e81a";
}
.icon-energetic:before {
  content: "\e82b";
}
.icon-email:before {
  content: "\e82c";
}
.icon-elipses:before {
  content: "\e82d";
}
.icon-down:before {
  content: "\e82e";
}
.icon-anxious:before {
  content: "\e833";
}
.icon-angry2:before {
  content: "\e834";
}
.icon-home2:before {
  content: "\e901";
}
.icon-DoooLogoIcon:before {
  content: "\e902";
}
.icon-Whistle:before {
  content: "\e903";
}
.icon-firstaid:before {
  content: "\e904";
}
.icon-HandShake:before {
  content: "\e905";
}
.icon-Car:before {
  content: "\e906";
}
.icon-Gust:before {
  content: "\e907";
}
.icon-Wheat:before {
  content: "\e908";
}
.icon-Yield:before {
  content: "\e909";
}
.icon-Needle:before {
  content: "\e90a";
}
.icon-NoChair:before {
  content: "\e90b";
}
.icon-qr-code:before {
  content: "\e90c";
}
.icon-Scale:before {
  content: "\e90d";
}
.icon-Spine:before {
  content: "\e90e";
}
.icon-WineGlass:before {
  content: "\e90f";
}
.icon-DoooLogo:before {
  content: "\e910";
}
.icon-OptimityWordMark:before {
  content: "\e911";
}
.icon-light-bulb:before {
  content: "\f000";
}
.icon-repo:before {
  content: "\f001";
}
.icon-repo-forked:before {
  content: "\f002";
}
.icon-repo-push:before {
  content: "\f005";
}
.icon-repo-pull:before {
  content: "\f006";
}
.icon-book4:before {
  content: "\f007";
}
.icon-octoface:before {
  content: "\f008";
}
.icon-git-pull-request:before {
  content: "\f009";
}
.icon-mark-github:before {
  content: "\f00a";
}
.icon-cloud-download3:before {
  content: "\f00b";
}
.icon-cloud-upload3:before {
  content: "\f00c";
}
.icon-keyboard:before {
  content: "\f00d";
}
.icon-gist:before {
  content: "\f00e";
}
.icon-file-code:before {
  content: "\f010";
}
.icon-file-text:before {
  content: "\f011";
}
.icon-file-media:before {
  content: "\f012";
}
.icon-file-zip:before {
  content: "\f013";
}
.icon-file-pdf:before {
  content: "\f014";
}
.icon-tag3:before {
  content: "\f015";
}
.icon-file-directory:before {
  content: "\f016";
}
.icon-file-submodule:before {
  content: "\f017";
}
.icon-person:before {
  content: "\f018";
}
.icon-jersey:before {
  content: "\f019";
}
.icon-git-commit:before {
  content: "\f01f";
}
.icon-git-branch:before {
  content: "\f020";
}
.icon-git-merge:before {
  content: "\f023";
}
.icon-mirror:before {
  content: "\f024";
}
.icon-issue-opened:before {
  content: "\f026";
}
.icon-issue-reopened:before {
  content: "\f027";
}
.icon-issue-closed:before {
  content: "\f028";
}
.icon-star:before {
  content: "\f02a";
}
.icon-comment2:before {
  content: "\f02b";
}
.icon-question:before {
  content: "\f02c";
}
.icon-alert:before {
  content: "\f02d";
}
.icon-search3:before {
  content: "\f02e";
}
.icon-gear:before {
  content: "\f02f";
}
.icon-radio-tower:before {
  content: "\f030";
}
.icon-tools:before {
  content: "\f031";
}
.icon-sign-out:before {
  content: "\f032";
}
.icon-rocket:before {
  content: "\f033";
}
.icon-rss2:before {
  content: "\f034";
}
.icon-clippy:before {
  content: "\f035";
}
.icon-sign-in:before {
  content: "\f036";
}
.icon-organization2:before {
  content: "\f037";
}
.icon-device-mobile:before {
  content: "\f038";
}
.icon-unfold:before {
  content: "\f039";
}
.icon-check3:before {
  content: "\f03a";
}
.icon-mail4:before {
  content: "\f03b";
}
.icon-mail-read:before {
  content: "\f03c";
}
.icon-arrow-up4:before {
  content: "\f03d";
}
.icon-arrow-right322:before {
  content: "\f03e";
}
.icon-arrow-down4:before {
  content: "\f03f";
}
.icon-arrow-left4:before {
  content: "\f040";
}
.icon-pin2:before {
  content: "\f041";
}
.icon-gift2:before {
  content: "\f042";
}
.icon-graph:before {
  content: "\f043";
}
.icon-triangle-left:before {
  content: "\f044";
}
.icon-credit-card:before {
  content: "\f045";
}
.icon-clock5:before {
  content: "\f046";
}
.icon-ruby:before {
  content: "\f047";
}
.icon-broadcast:before {
  content: "\f048";
}
.icon-key2:before {
  content: "\f049";
}
.icon-repo-force-push:before {
  content: "\f04a";
}
.icon-repo-clone:before {
  content: "\f04c";
}
.icon-diff:before {
  content: "\f04d";
}
.icon-eye4:before {
  content: "\f04e";
}
.icon-comment-discussion:before {
  content: "\f04f";
}
.icon-mail-reply:before {
  content: "\f051";
}
.icon-primitive-dot:before {
  content: "\f052";
}
.icon-primitive-square:before {
  content: "\f053";
}
.icon-device-camera:before {
  content: "\f056";
}
.icon-device-camera-video:before {
  content: "\f057";
}
.icon-pencil2:before {
  content: "\f058";
}
.icon-info2:before {
  content: "\f059";
}
.icon-triangle-right:before {
  content: "\f05a";
}
.icon-triangle-down:before {
  content: "\f05b";
}
.icon-link3:before {
  content: "\f05c";
}
.icon-plus4:before {
  content: "\f05d";
}
.icon-three-bars:before {
  content: "\f05e";
}
.icon-code:before {
  content: "\f05f";
}
.icon-location3:before {
  content: "\f060";
}
.icon-list-unordered:before {
  content: "\f061";
}
.icon-list-ordered:before {
  content: "\f062";
}
.icon-quote:before {
  content: "\f063";
}
.icon-versions:before {
  content: "\f064";
}
.icon-color-mode:before {
  content: "\f065";
}
.icon-screen-full:before {
  content: "\f066";
}
.icon-screen-normal:before {
  content: "\f067";
}
.icon-calendar3:before {
  content: "\f068";
}
.icon-beer:before {
  content: "\f069";
}
.icon-lock3:before {
  content: "\f06a";
}
.icon-diff-added:before {
  content: "\f06b";
}
.icon-diff-removed:before {
  content: "\f06c";
}
.icon-diff-modified:before {
  content: "\f06d";
}
.icon-diff-renamed:before {
  content: "\f06e";
}
.icon-horizontal-rule:before {
  content: "\f070";
}
.icon-arrow-small-right:before {
  content: "\f071";
}
.icon-jump-down:before {
  content: "\f072";
}
.icon-jump-up:before {
  content: "\f073";
}
.icon-move-left:before {
  content: "\f074";
}
.icon-milestone:before {
  content: "\f075";
}
.icon-checklist2:before {
  content: "\f076";
}
.icon-megaphone2:before {
  content: "\f077";
}
.icon-chevron-right:before {
  content: "\f078";
}
.icon-bookmark:before {
  content: "\f07b";
}
.icon-settings3:before {
  content: "\f07c";
}
.icon-dashboard:before {
  content: "\f07d";
}
.icon-history2:before {
  content: "\f07e";
}
.icon-link-external:before {
  content: "\f07f";
}
.icon-mute2:before {
  content: "\f080";
}
.icon-x3:before {
  content: "\f081";
}
.icon-circle-slash:before {
  content: "\f084";
}
.icon-pulse2:before {
  content: "\f085";
}
.icon-sync:before {
  content: "\f087";
}
.icon-telescope:before {
  content: "\f088";
}
.icon-microscope:before {
  content: "\f089";
}
.icon-alignment-align:before {
  content: "\f08a";
}
.icon-alignment-unalign:before {
  content: "\f08b";
}
.icon-gist-secret:before {
  content: "\f08c";
}
.icon-home3:before {
  content: "\f08d";
}
.icon-alignment-aligned-to:before {
  content: "\f08e";
}
.icon-stop4:before {
  content: "\f08f";
}
.icon-bug:before {
  content: "\f091";
}
.icon-logo-github:before {
  content: "\f092";
}
.icon-file-binary:before {
  content: "\f094";
}
.icon-database:before {
  content: "\f096";
}
.icon-server2:before {
  content: "\f097";
}
.icon-diff-ignored:before {
  content: "\f099";
}
.icon-ellipsis2:before {
  content: "\f09a";
}
.icon-no-newline:before {
  content: "\f09c";
}
.icon-hubot:before {
  content: "\f09d";
}
.icon-hourglass:before {
  content: "\f09e";
}
.icon-arrow-small-up:before {
  content: "\f09f";
}
.icon-arrow-small-down:before {
  content: "\f0a0";
}
.icon-arrow-small-left:before {
  content: "\f0a1";
}
.icon-chevron-up:before {
  content: "\f0a2";
}
.icon-chevron-down:before {
  content: "\f0a3";
}
.icon-chevron-left:before {
  content: "\f0a4";
}
.icon-jump-left:before {
  content: "\f0a5";
}
.icon-jump-right:before {
  content: "\f0a6";
}
.icon-move-up:before {
  content: "\f0a7";
}
.icon-move-down:before {
  content: "\f0a8";
}
.icon-move-right:before {
  content: "\f0a9";
}
.icon-triangle-up:before {
  content: "\f0aa";
}
.icon-git-compare:before {
  content: "\f0ac";
}
.icon-podium:before {
  content: "\f0af";
}
.icon-file-symlink-file:before {
  content: "\f0b0";
}
.icon-file-symlink-directory:before {
  content: "\f0b1";
}
.icon-squirrel:before {
  content: "\f0b2";
}
.icon-globe2:before {
  content: "\f0b6";
}
.icon-unmute:before {
  content: "\f0ba";
}
.icon-playback-pause:before {
  content: "\f0bb";
}
.icon-playback-rewind:before {
  content: "\f0bc";
}
.icon-playback-fast-forward:before {
  content: "\f0bd";
}
.icon-mention:before {
  content: "\f0be";
}
.icon-playback-play:before {
  content: "\f0bf";
}
.icon-puzzle:before {
  content: "\f0c0";
}
.icon-package:before {
  content: "\f0c4";
}
.icon-browser:before {
  content: "\f0c5";
}
.icon-split:before {
  content: "\f0c6";
}
.icon-steps:before {
  content: "\f0c7";
}
.icon-terminal:before {
  content: "\f0c8";
}
.icon-markdown:before {
  content: "\f0c9";
}
.icon-dash:before {
  content: "\f0ca";
}
.icon-fold:before {
  content: "\f0cc";
}
.icon-inbox3:before {
  content: "\f0cf";
}
.icon-trashcan:before {
  content: "\f0d0";
}
.icon-paintcan:before {
  content: "\f0d1";
}
.icon-flame:before {
  content: "\f0d2";
}
.icon-briefcase2:before {
  content: "\f0d3";
}
.icon-plug:before {
  content: "\f0d4";
}
.icon-circuit-board:before {
  content: "\f0d6";
}
.icon-mortar-board:before {
  content: "\f0d7";
}
.icon-law:before {
  content: "\f0d8";
}
.icon-dollar:before {
  content: "\f155";
}
.icon-device-desktop:before {
  content: "\f27c";
}


